import React from "react";
import SinglePageWrapper from "components/SinglePageWrapper/SinglePageWrapper";
import styled from "styled-components";
import Header from "components/SinglePageWrapper/Header";
import Paragraph from "components/Paragraph";

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  margin: 24px auto;
  font-size: 1.5rem;
`;

const IntroductionNotAllowed: React.FC = () => {
  return (
    <SinglePageWrapper centered>
      <div>
        <Header>Nie masz dostępu do procesu wdrożenia.</Header>
        <StyledParagraph colored>
          Podany link jest nieprawidłowy.
        </StyledParagraph>
      </div>
    </SinglePageWrapper>
  );
};

export default IntroductionNotAllowed;
