import React from "react";
import styled from "styled-components";
import Header from "components/SinglePageWrapper/Header";
import LinkButton from "./LinkButton";
import YouTubeVideo from "./YouTubeVideo";
import Paragraph from "components/Paragraph";
import { IStep } from "./IStep";

const StyledParagraph = styled(Paragraph)`
  margin: 36px auto;
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.light};

  ${({ theme }) => theme.media.min.tablet`
    padding: 0 48px;
  `}

  ${({ theme }) => theme.media.min.laptop`
    font-size: 1.15rem;
  `}
`;

const stepFactory: (onVideoEnd: () => void) => IStep[] = (onVideoEnd) => [
  {
    mainElement: <YouTubeVideo videoId="yx10w6P6sZA" onVideoEnd={onVideoEnd} />,
    content: (
      <>
        <Header>1. Wprowadzenie</Header>
        <StyledParagraph spaced colored>
          Cześć, z tej strony Adrian Szerszeń. Przed Tobą szkolenie sprzedażowe,
          które przygotowywałem przez ostatnie 3 miesiące na podstawie
          współpracy z ponad setką gabinetów w ciągu ostatnich 8 lat.
        </StyledParagraph>
      </>
    ),
  },
  {
    mainElement: <YouTubeVideo videoId="xNthxETEIKY" onVideoEnd={onVideoEnd} />,
    content: (
      <>
        <Header>2. Zmiana nastawienia</Header>
        <StyledParagraph spaced colored>
          Gdy zmienisz perspektywę sprzedaży, zaczniesz postrzegać siebie jako
          doradcę, który pomaga w kupowaniu.
          <br />
          <br />
          Pamiętaj, że nawet jeśli masz piękne prace, mnóstwo certyfikatów,
          umiejętności sprzedażowe ale nieodpowiednie przekonanie że sprzedaż
          kojarzy Ci się z manipulowaniem, wciskaniem i narzucaniem się, to
          będzie to sabotować Twoje wyniki sprzedaży.
        </StyledParagraph>
      </>
    ),
  },
  {
    mainElement: <YouTubeVideo videoId="L1MF5WA-6yw" onVideoEnd={onVideoEnd} />,
    content: (
      <div>
        <Header>3. 5 zasad sprzedaży</Header>
        <StyledParagraph spaced colored>
          Hej, w tej lekcji przedstawię Ci 5 zasad sprzedaży, dzięki którym
          uświadomisz sobie, co różni dobrych sprzedawców, którzy osiągają
          bardzo dobre efekty, od osób, które tych efektów nie mają.
        </StyledParagraph>
      </div>
    ),
  },
  {
    mainElement: <YouTubeVideo videoId="B_ggnsweUN0" onVideoEnd={onVideoEnd} />,
    content: (
      <div>
        <Header>4. Wciel się w klienta</Header>
        <StyledParagraph spaced colored>
          Jeśli zostanie zdiagnozowany problem oraz potrzeba, to jesteś w stanie
          dać rozwiązanie, czyli zaprosić na konsultację, na której powiesz jej
          więcej na temat tego, jak możesz jej pomóc.
          <br />
          <br />
          Problemem większości właścicielek gabinetów jest to, że nie
          zdiagnozowały tych dwóch rzeczy i od razu dają rozwiązanie, czyli
          piszą suchą cenę lub oferują zabieg bądź konsultację.
        </StyledParagraph>
      </div>
    ),
  },
  {
    mainElement: <YouTubeVideo videoId="jCmaXha58_4" onVideoEnd={onVideoEnd} />,
    content: (
      <>
        <Header>5. Proces sprzedaży z lotu ptaka</Header>
        <StyledParagraph spaced colored>
          Najlepsze gabinety, które dopracowały ten proces do perfekcji osiągają
          najwyższe wyniki sprzedażowe.
        </StyledParagraph>
      </>
    ),
  },
  {
    mainElement: <YouTubeVideo videoId="Ue_02zWE-0M" onVideoEnd={onVideoEnd} />,
    content: (
      <div>
        <Header>6. Rozmowa telefoniczna</Header>
        <StyledParagraph spaced colored>
          Rozmowę telefoniczną podzieliłem na 6 etapów.
          <br />
          <br />
          Musisz pamiętać, że to, co Ci przedstawię, to tylko schemat, na
          podstawie którego, w połączeniu z własnym stylem, przeprowadzisz
          idealną rozmowę.
        </StyledParagraph>
        <LinkButton
          as="a"
          target="_blank"
          href="https://docs.google.com/document/d/14Ds8BylG1IboQ3BH5QcgGVPn-YV3u8tKGVb5iOkfmDw/edit"
          rel="noreferrer"
        >
          Szablon wiadomości
        </LinkButton>
      </div>
    ),
  },
  {
    mainElement: <YouTubeVideo videoId="tg4xejHMjE8" onVideoEnd={onVideoEnd} />,
    content: (
      <div>
        <Header>7. Zbijanie obiekcji</Header>
        <StyledParagraph spaced colored>
          Okej, jesteśmy na samym końcu. W tym materiale chciałbym zagłębić Cię
          w temat obiekcji, jakie klientki mają przed umówieniem się na zabieg
          lub konsultacje. Wybrałem te obiekcje, które najczęściej pojawiają się
          w rozmowach z potencjalnymi klientkami.
        </StyledParagraph>
      </div>
    ),
  },
  {
    content: (
      <div>
        <Header>To wszystko!</Header>
        <StyledParagraph spaced colored>
          Dziękuję Ci za przejście całego procesu wdrożenia! Teraz nie pozostało
          nic innego, tylko sprawić, by klientki zajęły wszystkie terminy w
          Twoim gabinecie!
        </StyledParagraph>
      </div>
    ),
  },
];

export default stepFactory;
