import React from "react";
import styled from "styled-components";
import YouTube from "react-youtube";
import { StyledProps } from "types/StyledProps";

interface YouTubeVideoProps extends StyledProps {
  videoId: string;

  onVideoEnd?: () => void;
}

const YouTubeVideo: React.FC<YouTubeVideoProps> = (props: YouTubeVideoProps) => {
  return (
    <div className={props.className}>
      <YouTube
        videoId={props.videoId}
        opts={{
          width: "100%",
          height: "100%",
        }}
        onEnd={props.onVideoEnd}
      />
    </div>
  );
}

const StyledYouTubeVideo = styled(YouTubeVideo)`
  position: relative;
  padding-top: 56.25%;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default StyledYouTubeVideo;
