import styled from "styled-components";

const LinkButton = styled.a`
  display: block;
  width: max-content;
  position: relative;
  text-align: center;
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  background: -webkit-linear-gradient(
    ${({ theme }) => theme.colors.mainLight},
    ${({ theme }) => theme.colors.mainDark}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 24px 18px;
  margin: 48px auto;

  &::after {
    content: "";
    display: block;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.light};
    border-radius: 100vw;
    box-shadow: 0 5px 24px 0 rgba(72, 136, 232, 0.5);
    opacity: 0.5;
    transition: opacity 0.25s, transform 0.25s;
  }

  &:hover::after {
    opacity: 1;
  }

  ${({ theme }) => theme.media.min.laptop`
        padding: 24px 36px;
        font-size: 1.5rem;
    `}
`;

export default LinkButton;
