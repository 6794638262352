import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useQueryParam, StringParam } from "use-query-params";
import IntroductionProcess from "components/IntrodutcionProcess/IntroductionProcess";
import GlobalStyle from "styles/GlobalStyle";
import theme from "styles/theme";
import IntroductionNotAllowed from "components/IntrodutcionProcess/IntroductionNotAllowed";
import Helmet from "components/Helmet";

const Introduction: React.FC = () => {
  const [keyParam] = useQueryParam("key", StringParam);
  const [isContentBlocked, setIsContentBlocked] = useState(true);

  const isKeyValid = () => {
    const validKeys =
      process.env.GATSBY_INTROUDCTION_STEPS_ALLOWED_KEYS?.split(";");

    if (!validKeys || !keyParam) {
      return;
    }

    return validKeys.includes(keyParam);
  };

  useEffect(() => {
    setIsContentBlocked(!(keyParam && isKeyValid()));
  }, [keyParam]);

  return (
    <ThemeProvider theme={theme}>
      <Helmet customTitle="Wprowadzenie do współpracy - Agencja FollowUP" />
      <GlobalStyle />
      {isContentBlocked ? (
        <IntroductionNotAllowed />
      ) : (
        <IntroductionProcess />
      )}
    </ThemeProvider>
  );
};

export default Introduction;
