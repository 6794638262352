import React, { useRef, useState, useEffect, PropsWithChildren } from "react";
import styled from "styled-components";
import { ReactComponent as RoundArrowSvg } from "assets/svg/round-arrow.svg";
import BaseButton from "components/Button";
import { StyledProps } from "types/StyledProps";
import Paragraph from "components/Paragraph";

const StyledBaseButton = styled(BaseButton)`
  margin-top: 48px;
  margin-bottom: 8px;
  transition: background-color 5s transform 0.25s;

  ${({ theme }) => theme.media.min.laptop`
    font-size: 1rem;
  `}

  :disabled {
    transform: revert !important;
    background: gray;
    cursor: default;
  }

  :disabled:hover {
    transform: revert !important;
  }
`;

const StyledRoundArrow = styled(RoundArrowSvg)<{ hidden: boolean }>`
  position: absolute;
  top: 50%;
  right: 50%;
  width: 100px;
  transform: translateX(150%);
  transition: opacity 0.75s;

  opacity: ${({ hidden }) => (hidden ? 0 : 1)};

  ${({ theme }) => theme.media.min.laptop`
    font-size: 1.15rem;
    transform: scale(1.15) translate(150%, 15%);
  `}
`;

const StepCounter = styled(Paragraph)`
  margin: 0;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

interface ButtonProps extends PropsWithChildren<StyledProps> {
  currentStep: number;
  maxStep: number;
  disabled: boolean;

  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({
  className,
  children,
  onClick,
  currentStep,
  maxStep,
  disabled,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <div className={className}>
      <StyledBaseButton
        disabled={disabled}
        ref={buttonRef}
        onClick={onClick}
        colored
      >
        {children}
      </StyledBaseButton>
      <StepCounter colored>
        {currentStep}&nbsp;/&nbsp;{maxStep}
      </StepCounter>
      <StyledRoundArrow hidden={disabled} />
    </div>
  );
};

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 56px;
`;

export default StyledButton;
