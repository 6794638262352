import React, { useEffect, useLayoutEffect, useState } from "react";
import Button from "components/SinglePageWrapper/Button";
import { useQueryParam, NumberParam } from "use-query-params";
import SinglePageWrapper from "components/SinglePageWrapper/SinglePageWrapper";
import BackButton from "./BackButton";
import stepFactory from "./stepsFactory";
import AgendaCard from "./AgendaCard/AgendaCard";

const maxStepStorageKey = "maxStep";

const IntroductionProcess: React.FC = () => {
  const [currentStepParam, setCurrentStepParam] = useQueryParam(
    "step",
    NumberParam
  );

  const [maxStep, setMaxStep] = useState(0);

  useEffect(() => {
    const maxStepLS = localStorage.getItem(maxStepStorageKey);

    if (!maxStepLS || Number.isNaN(Number(maxStepLS))) {
      localStorage.setItem(maxStepStorageKey, "0");
    } else {
      setMaxStep(Number(maxStepLS));
    }
  }, []);

  const [currentStep, setCurrentStep] = useState(currentStepParam || 0);

  const steps = stepFactory(() =>
    setMaxStep((current) => {
      const newValue = current + 1;
      localStorage.setItem(maxStepStorageKey, newValue.toString());
      return newValue;
    })
  );

  const onButtonClicked = () => {
    const initialValue = currentStep;
    setCurrentStep(initialValue + 1);
    setCurrentStepParam(initialValue + 1);
    setMaxStep((current) => Math.max(current, initialValue + 1));
  };

  const onBackButtonClicked = () => {
    const initialValue = currentStep;
    setCurrentStep(initialValue - 1);
    setCurrentStepParam(initialValue - 1);
  };

  useLayoutEffect(() => {
    if (!currentStep) {
      setCurrentStepParam(0, "replaceIn");
      setCurrentStep(0);
    } else {
      const newStep = currentStep % steps.length;
      setCurrentStepParam(newStep, "replaceIn");
      setCurrentStep(newStep);
    }
  }, []);

  const firstStep = currentStep <= 0;
  const lastStep = currentStep >= steps.length - 1;

  return (
    <SinglePageWrapper
      centered={lastStep}
      mainElement={steps[currentStep]?.mainElement}
    >
      {steps[currentStep]?.content}
      {!lastStep && (
        <Button
          currentStep={currentStep + 1}
          maxStep={steps.length - 1}
          onClick={onButtonClicked}
          disabled={maxStep <= currentStep}
        >
          PRZEJDŹ DALEJ
        </Button>
      )}
      {!lastStep && <AgendaCard maxStep={maxStep} />}
      {!firstStep && !lastStep && <BackButton onClick={onBackButtonClicked} />}
    </SinglePageWrapper>
  );
};

export default IntroductionProcess;
