import React from "react";
import styled, { css } from "styled-components";
import Card from "components/Card";
import Header from "components/Header";
import { StyledProps } from "types/StyledProps";
import { useQueryParam, StringParam, NumberParam } from "use-query-params";

interface AgendaCardProps extends StyledProps {
  maxStep: number;
}

const disabledLinkStyles = css`
  filter: saturate(0);
  pointer-events: none;
`;

const activeLinkStyles = css`
  font-weight: bold;
  cursor: default;
`;

const AgendaLink = styled.a<{ $active: boolean; $disabled?: boolean }>`
  ${({ $active, $disabled }) => $active && !$disabled && activeLinkStyles}
  ${({ $disabled }) => $disabled && disabledLinkStyles}
`;

const AgendaCard: React.FC<AgendaCardProps> = ({
  className,
  maxStep,
}: AgendaCardProps) => {
  const [keyParam] = useQueryParam("key", StringParam);
  const [stepParam] = useQueryParam("step", NumberParam);

  return (
    <Card className={className}>
      <Header colored>Spis treści</Header>
      <AgendaLink
        $active={stepParam === 0}
        href={`/wprowadzenie-do-wspolpracy/?key=${keyParam}&step=0`}
      >
        1. Wprowadzenie
      </AgendaLink>
      <AgendaLink
        $disabled={maxStep < 1}
        $active={stepParam === 1}
        href={`/wprowadzenie-do-wspolpracy/?key=${keyParam}&step=1`}
      >
        2. Zmiana nastawienia
      </AgendaLink>
      <AgendaLink
        $disabled={maxStep < 2}
        $active={stepParam === 2}
        href={`/wprowadzenie-do-wspolpracy/?key=${keyParam}&step=2`}
      >
        3. 5 zasad sprzedaży
      </AgendaLink>
      <AgendaLink
        $disabled={maxStep < 3}
        $active={stepParam === 3}
        href={`/wprowadzenie-do-wspolpracy/?key=${keyParam}&step=3`}
      >
        4. Wciel się w klienta
      </AgendaLink>
      <AgendaLink
        $disabled={maxStep < 4}
        $active={stepParam === 4}
        href={`/wprowadzenie-do-wspolpracy/?key=${keyParam}&step=4`}
      >
        5. Proces sprzedaży z lotu ptaka
      </AgendaLink>
      <AgendaLink
        $disabled={maxStep < 5}
        $active={stepParam === 5}
        href={`/wprowadzenie-do-wspolpracy/?key=${keyParam}&step=5`}
      >
        6. Rozmowa telefoniczna
      </AgendaLink>
      <AgendaLink
        $disabled={maxStep < 6}
        $active={stepParam === 6}
        href={`/wprowadzenie-do-wspolpracy/?key=${keyParam}&step=6`}
      >
        7. Zbijanie obiekcji
      </AgendaLink>
    </Card>
  );
};

const StyledAgendaCard = styled(AgendaCard)`
  width: 300px;
  padding: 24px 28px;
  border-radius: 16px;
  align-items: flex-start;
  text-align: left;

  a {
    text-decoration: none;
    margin-top: 12px;
    background: ${({ theme }) =>
      `-webkit-linear-gradient(${theme.colors.mainLight}, ${theme.colors.mainDark})`};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  a:visited {
    color: initial;
  }

  a:hover {
    filter: saturate(1.5);
  }

  ${Header} {
    margin-bottom: 16px;

    @media (max-width: 1600px) {
      align-self: center;
      ::before {
        border-radius: 0;
        margin: 0 auto;
        margin-bottom: 12px;
      }
    }
  }

  ${({ theme }) => theme.media.max.desktop`
      margin: 0 36px;
      margin-bottom: 36px;
      width: auto;
    `}

  @media (min-width: 1600px) {
    position: absolute;
    left: calc(848px + (100% - 800px) / 2);
    top: 32px;
  }
`;

export default StyledAgendaCard;
